import React from "react";
import { Link } from "react-router-dom";

function ExamListItem(props) {
  const examPageLink = `/${props.examId}`;
  const splitName = props.examName.split(" ");
  const examYear = [...splitName].pop();
  const examNameWoYear = [...splitName].slice(0, -1).join(" ");

  return (
    <li>
      <Link className="examListName" to={examPageLink}>
        {examNameWoYear} <span className="examYear">{examYear}</span>
      </Link>
    </li>
  );
}

export default ExamListItem;

import React, { Component } from "react";
import "./App.css";
import Header from "./header";
import PageHeading from "./pageHeading";
import ExamListItem from "./examListItem";
import SearchField from "react-search-field";
import * as examConfig from "./examConfigs.json";

class App extends Component {
  state = {
    search: ""
  };
  render() {
    const items = examConfig.exams.map(item => {
      if (
        this.state.search === "" ||
        item.name.toLowerCase().includes(this.state.search.toLowerCase())
      ) {
        return (
          <ExamListItem examName={item.name} examId={item.id} key={item.id} />
        );
      } else {
        return false;
      }
    });
    const onChange = (val, e) => {
      this.setState({
        search: val
      });
    };
    return (
      <div className="App">
        <div className="wrapper">
          <Header />
          <PageHeading heading="Select Exam" />
          <div className="list-search">
            <SearchField
              placeholder="Search Exam"
              searchText={this.state.search}
              onChange={onChange}
            />
            <ul className="exams">{items}</ul>
          </div>
        </div>
      </div>
    );
  }
}

export default App;

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import Upload from "./upload.js";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import "typeface-poppins";
// import UploadPage from "./upload_v2";

const routing = (
  <Router>
    <Switch>
      <Route exact path="/" component={App} />
      {/* <Route exact path="//" component={UploadPage} /> */}
      <Route path="/:eid" component={Upload} />
      {/* <Route path="/:eid" component={UploadPage} /> */}
    </Switch>
  </Router>
);

ReactDOM.render(routing, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, { Component } from "react";
import "./App.css";
import Header from "./header";
import PageHeading from "./pageHeading";
import { Link } from "react-router-dom";
import * as examConfig from "./examConfigs.json";
import EXIF from "exif-js";
import ReactS3 from "react-s3";
import Axios from "axios";

const config = {
  bucketName: "reactpassportal",
  region: "us-east-1",
  accessKeyId: "AKIAJ36XDEWAQDHFJPTA",
  secretAccessKey: "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"
};
class Upload extends Component {
  state = {
    currentState: "Select Image",
    imageOrientation: 1,
    finalUrl: "",
    loading: false,
    heading: "Select Image",
    username: ""
  };

  findOrientation = event => {
    // debugger;
    let file = event.target.files[0];
    let self = this;
    EXIF.getData(file, function() {
      let orientation = EXIF.getTag(this, "Orientation");
      // console.log(orientation);
      if (orientation !== undefined && orientation !== 1) {
        self.setOrientationState(orientation);
        self.setOptimisedImage(file, orientation);
      } else {
        self.setOptimisedImage(file, 1);
      }
      // self.resizeImage(orientedImage);
    });
  };

  dataURItoBlob = function(dataURI) {
    let bytes =
      dataURI.split(",")[0].indexOf("base64") >= 0
        ? atob(dataURI.split(",")[1])
        : unescape(dataURI.split(",")[1]);
    let mime = dataURI
      .split(",")[0]
      .split(":")[1]
      .split(";")[0];
    let max = bytes.length;
    let ia = new Uint8Array(max);
    for (let i = 0; i < max; i++) ia[i] = bytes.charCodeAt(i);
    return new Blob([ia], { type: mime });
  };

  setOptimisedImage = (file, orientation) => {
    let maxSize = 600;
    let canvas = document.createElement("canvas");
    let context = canvas.getContext("2d");
    let img = new Image();
    let reader = new FileReader();
    reader.readAsDataURL(file);

    let self = this;

    reader.onload = function(evt) {
      if (evt.target.readyState === FileReader.DONE) {
        img.src = evt.target.result;
        img.onload = () => {
          let width = img.width;
          let height = img.height;
          if (width > height) {
            if (width > maxSize) {
              height *= maxSize / width;
              width = maxSize;
            }
          } else {
            if (height > maxSize) {
              width *= maxSize / height;
              height = maxSize;
            }
          }
          canvas.width = width;
          canvas.height = height;
          context.save();
          switch (+orientation) {
            // 1 = The 0th row is at the visual top of the image, and the 0th column is the visual left-hand side.
            case 1:
              break;

            // 2 = The 0th row is at the visual top of the image, and the 0th column is the visual right-hand side.
            case 2:
              context.translate(width, 0);
              context.scale(-1, 1);
              break;

            // 3 = The 0th row is at the visual bottom of the image, and the 0th column is the visual right-hand side.
            case 3:
              context.translate(width, height);
              context.rotate((180 / 180) * Math.PI);
              break;

            // 4 = The 0th row is at the visual bottom of the image, and the 0th column is the visual left-hand side.
            case 4:
              context.translate(0, height);
              context.scale(1, -1);
              break;

            // 5 = The 0th row is the visual left-hand side of the image, and the 0th column is the visual top.
            case 5:
              canvas.width = height;
              canvas.height = width;
              context.rotate((90 / 180) * Math.PI);
              context.scale(1, -1);
              break;

            // 6 = The 0th row is the visual right-hand side of the image, and the 0th column is the visual top.
            case 6:
              canvas.width = height;
              canvas.height = width;
              context.rotate((90 / 180) * Math.PI);
              context.translate(0, -height);
              break;

            // 7 = The 0th row is the visual right-hand side of the image, and the 0th column is the visual bottom.
            case 7:
              canvas.width = height;
              canvas.height = width;
              context.rotate((270 / 180) * Math.PI);
              context.translate(-width, height);
              context.scale(1, -1);
              break;

            // 8 = The 0th row is the visual left-hand side of the image, and the 0th column is the visual bottom.
            case 8:
              canvas.width = height;
              canvas.height = width;
              context.translate(0, width);
              context.rotate((270 / 180) * Math.PI);
              break;

            default:
              break;
          }
          context.drawImage(img, 0, 0, width, height);
          let dataUrl = canvas.toDataURL("image/jpeg");
          let OptimisedBlob = self.dataURItoBlob(dataUrl);
          let OptimisedFile = new File([OptimisedBlob], file.name, {
            type: "image/jpeg",
            lastModified: Date.now()
          });
          console.log(OptimisedFile);
          self.uploadImage(OptimisedFile);
        };
      }
    };
  };

  uploadImage = (file, props) => {
    let { params } = this.props.match;
    let isSelectedExam = exam => exam.id === params.eid;
    let examObject = examConfig.exams.find(isSelectedExam);
    let username = this.state.username;
    this.setState({
      loading: true,
      currentState: "Optimizing"
    });

    // var postData = {
    //   finalheight: 400,
    //   dpi: 200,
    //   finalwidth: 300,
    //   maxfilesize: 100,
    //   examname: "CAT",
    //   unit: "px",
    //   username: "Himanshu Pal",
    //   dob: "26.02.1993"
    // };

    // let axiosConfig = {
    //   headers: {
    //     "Content-Type": "application/json",
    //     "x-api-key": "Mts0DYL27f3WmePUObWWb44c3qfbEOAK59qoMeLO",
    //     "Access-Control-Allow-Origin": "*"
    //   }
    // };

    // Axios.post(
    //   "https://44uq4o9mfg.execute-api.us-east-1.amazonaws.com/prod/uploadimage",
    //   postData,
    //   axiosConfig
    // )
    //   .then(res => {
    //     console.log("RESPONSE RECEIVED: ", res);
    //   })
    //   .catch(err => {
    //     console.log("AXIOS ERROR: ", err);
    //   });

    ReactS3.uploadFile(file, config).then(data => {
      console.log(data.location);
      Axios.get(
        `https://ps5e0mlwvf.execute-api.us-east-1.amazonaws.com/prod/passportal?url=${
          data.location
        }&unit=${examObject.unit}&final_height=${
          examObject.height
        }&final_width=${examObject.width}&dpi=${examObject.dpi}&namestr=${
          examObject.id
        }&maxfilesize=${examObject.maxFileSize}&username=${username}`
      )
        .then(res => {
          console.log(res.data.body.file_url);
          this.setState({
            finalUrl: res.data.body.file_url,
            loading: false,
            currentState: "Done. Try Again?",
            heading: "Download"
          });
        })
        .catch(error => {
          console.log(error);
        });
    });
  };

  setOrientationState = orientation => {
    console.log(`orientation is ${orientation}`);
    this.setState({
      imageOrientation: orientation
    });
  };

  render() {
    // Get the exam id from the url
    // Find the exam object from the config json, so that data can be used else where in render
    let { params } = this.props.match;
    let isSelectedExam = exam => exam.id === params.eid;
    let examObject = examConfig.exams.find(isSelectedExam);
    let loader;
    if (this.state.loading === true) {
      loader = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30px"
          height="30px"
          viewBox="0 0 100 100"
          preserveAspectRatio="xMidYMid"
          class="lds-dual-ring"
        >
          <circle
            cx="50"
            cy="50"
            ng-attr-r="{{config.radius}}"
            ng-attr-stroke-width="{{config.width}}"
            ng-attr-stroke="{{config.stroke}}"
            ng-attr-stroke-dasharray="{{config.dasharray}}"
            fill="none"
            stroke-linecap="round"
            r="27"
            stroke-width="7"
            stroke="#201e31"
            stroke-dasharray="42.411500823462205 42.411500823462205"
            transform="rotate(197.983 50 50)"
          >
            <animateTransform
              attributeName="transform"
              type="rotate"
              calcMode="linear"
              values="0 50 50;360 50 50"
              keyTimes="0;1"
              dur="1s"
              begin="0s"
              repeatCount="indefinite"
            />
          </circle>
        </svg>
      );
    }
    let finalImg, desc, photoselector;
    if (this.state.finalUrl !== "") {
      finalImg = (
        <div>
          <img
            style={{ marginTop: "2em", display: "block" }}
            src={this.state.finalUrl}
            alt="output file"
          />
          <a href={this.state.finalUrl} download>
            <button className="dwld-btn">Download Image</button>
          </a>
        </div>
      );
    }
    if (this.state.currentState !== "Done. Try Again?") {
      desc = (
        <p>Select your original image to start the optimization process</p>
      );
      photoselector = (
        <div>
          <input
            type="file"
            id="fileSelector"
            accept="image/*"
            onChange={e => this.findOrientation(e)}
          />
          <label htmlFor="fileSelector" className="label-button">
            {loader} <span>{this.state.currentState}</span>
          </label>
        </div>
      );
    }

    return (
      <div className="App">
        <div className="wrapper">
          <Header />
          <PageHeading heading={`${examObject.name} Photo Optimizer`} />
          {desc}
          <div>
            {photoselector}
            {finalImg}
            <div className="back-link">
              <Link to="/">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="white"
                    d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"
                  />
                  <path fill="none" d="M0 0h24v24H0V0z" />
                </svg>
                <span>Exams List</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Upload;

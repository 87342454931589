import React from "react";
import { Link } from "react-router-dom";
import Inferface from "./inferface";

const Header = () => (
  <header>
    <Link to="/">
      <span className="logoText"> Exam Photo Optimizer</span>{" "}
    </Link>
    <span className="byif"> By Inferface</span> <Inferface />
  </header>
);
export default Header;
